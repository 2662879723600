<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Güncelleme Onayı Bekleyenler
        </b-card-title>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(username)="data">
            {{ data.item.username }}
            <div v-if="data.item.created">
              <small class="text-warning">{{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
            </div>
            <div v-if="data.item.open_user">
              <small class="text-primary"><b>Güncelleyen: </b>{{ data.item.open_user }}</small>
            </div>
          </template>
          <template #cell(goal)="data">
            <div v-if="data.item.brand">
              <small class="text-danger"><b>Marka: </b>{{ data.item.brand }}</small>
            </div>
            <div v-if="data.item.year">
              <small class="text-primary"><b>Yıl: </b>{{ data.item.year }}</small>
            </div>
            <div v-if="data.item.month">
              <small class="text-warning"><b>Ay: </b>{{ data.item.month }}</small>
            </div>
            <div v-if="data.item.goal">
              <small class="text-info"><b>Hedef: </b>{{ data.item.goal }}</small>
            </div>
          </template>
          <template #cell(com_consultant_goal_status)="data">
            <div v-if="data.item.id_com_consultant_goal_status === '1'">
              <p>
                <feather-icon icon="ClockIcon" />
                {{ data.item.com_consultant_goal_status }}
              </p>
            </div>
            <div v-if="data.item.id_com_consultant_goal_status === '2'">
              <p class="text-success">
                <feather-icon icon="CheckCircleIcon" />
                {{ data.item.com_consultant_goal_status }}
              </p>
            </div>
            <div v-if="data.item.id_com_consultant_goal_status === '3'">
              <p class="text-danger">
                <feather-icon icon="XCircleIcon" />
                {{ data.item.com_consultant_goal_status }}
              </p>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              v-if="data.item.id_com_consultant_goal_status === '1'"
              variant="primary"
              size="sm"
              @click="getDetailModal(data.item.id)"
            >
              Görüntüle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <template v-else>
        <b-alert
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Kayıt bulunamadı.
          </div>
        </b-alert>
      </template>
    </b-card>
    <b-modal
      v-model="detailModal"
      size="lg"
      centered
      hide-footer
      title="Detay"
    >
      <form-consultant-pending-view />

      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-muted font-small-2">
          Güncelleme isteği {{ moment(dataItem.created).format('llll') }} tarihinde {{ dataItem.open_user }} tarafından oluşturuldu.
        </div>
        <div>
          <b-button
            variant="success"
            class="mr-2"
            @click="acceptRequest(dataItem.id)"
          >
            <feather-icon icon="SaveIcon" />
            Onayla
          </b-button>

          <b-button
            variant="danger"
            @click="declineRequest(dataItem.id)"
          >
            <feather-icon icon="XIcon" />
            Reddet
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
  </div>
</template>
<script>
import {
  BCard, BCardTitle, BCardHeader, BTable, BCardFooter, BPagination, BAlert, BButton,
} from 'bootstrap-vue'
import FormConsultantPendingView from '@/views/Sales/Consultant_goals/Pending/View.vue'

export default {
  name: 'UpdatePendingGoals',
  components: {
    FormConsultantPendingView,
    BAlert,
    BPagination,
    BCardFooter,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      detailModal: false,
      dataQuery: {
        select: [
          'com_consultant_goal_pendings.id                            AS id',
          'com_user.name                                              AS username',
          'open_user.name                                             AS open_user',
          'com_consultant_goal_pendings.id_com_consultant_goal_status AS id_com_consultant_goal_status',
          'com_consultant_goal_status.title                           AS com_consultant_goal_status',
          'com_consultant_goal_pendings.year                          AS year',
          'com_consultant_goal_pendings.month                         AS month',
          'com_consultant_goal_pendings.goal                          AS goal',
          'com_brand.name                                             AS brand',
          'com_consultant_goal_pendings.created                       AS created',
        ],
        limit: 10,
        start: 0,
      },
      fields: [
        {
          key: 'username',
          label: 'Danışman',
        },
        {
          key: 'goal',
          label: 'Güncel Bilgiler',
        },
        {
          key: 'com_consultant_goal_status',
          label: 'Durum',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '140px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['consultantGoalPendings/getConsultantGoalPendings']
    },
    dataItem() {
      return this.$store.getters['consultantGoalPendings/getConsultantGoalPending']
    },
    consultantGoal() {
      return this.$store.getters['consultantGoals/getConsultantGoal']
    },
    dataCount() {
      return this.$store.getters['consultantGoalPendings/getConsultantGoalPendingsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('consultantGoalPendings/consultantGoalPendingsList', this.dataQuery)
    },
    getDetailModal(id) {
      this.$store.dispatch('consultantGoalPendings/consultantGoalPendingView', id)
        .then(response => {
          if (response) {
            this.$store.dispatch('consultantGoals/consultantGoalView', response.id_com_consultant_goal)
          }
          this.detailModal = true
        })
    },
    acceptRequest(id) {
      this.detailModal = false
      this.$swal({
        title: 'İsteği Onayla',
        text: 'İstek onaylandığında ilgili hedef kaydı güncellenecek(eski haline erişilemeyecek) ve bu işlem geri alınamayacak. Devam etmek istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.dataItem.id_com_consultant_goal_status = 2
          this.dataItem.id_com_user = this.consultantGoal.id_com_user
          this.$store.dispatch('consultantGoalPendings/consultantGoalPendingSave', this.dataItem)
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız!',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    declineRequest(id) {
      this.detailModal = false
      this.$swal({
        title: 'İsteği reddetmek istiyor musunuz ?',
        text: 'İşlem geri alınamayacak ve ilgili hedef kaydı güncellenmeyecek.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('consultantGoalPendings/consultantGoalPendingSave', { id, id_com_consultant_goal_status: 3 })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: 'İstek Reddedildi!',
                  text: 'İstek başarı ile reddedildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız!',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
