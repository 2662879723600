<template>
  <div>
    <b-list-group>
      <list-item
        title="Marka"
        :value="dataItem.brand"
      />
      <list-item
        title="Danışman"
        :value="dataItem.username"
      />
      <list-item
        title="Satış Hedefi"
        :value="dataItem.goal"
      />
      <list-item
        title="Tahsis Bağlantı"
        :value="dataItem.tahsis_baglanti"
      />
      <list-item
        title="Ay"
        :value="dataItem.month"
      />
      <list-item
        title="Yıl"
        :value="dataItem.year"
      />
      <list-item
        title="Telefon Görüşme"
        :value="dataItem.phone"
      />
      <list-item
        title="Web Görüşme"
        :value="dataItem.web"
      />
      <list-item
        title="Web Dist. Görüşme"
        :value="dataItem.web_distributor"
      />
      <list-item
        title="Showroom"
        :value="dataItem.showroom"
      />
      <list-item
        title="Test Sürüşü"
        :value="dataItem.test_drive"
      />
      <list-item
        title="Dijital Satış"
        :value="dataItem.digital_goal"
      />
    </b-list-group>
  </div>
</template>
<script>
import { BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Cars/view/ListItem.vue'

export default {
  name: 'FormConsultantPendingList',
  components: {
    ListItem,
    BListGroup,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
